import { Box, Typography } from '@mui/material';
import React from 'react';
import ReactLoading from "react-loading";

function Loading() {
    return (
        <Box
          sx={{
            height: "100vh",
            width: "100%",
            display: "flex",
            flexDirection:'column',
            justifyContent: "center",
            alignItems: "center",
            zIndex: "10",
            overflow: "hidden",
            "&::before":{
              opacity:0.5
            }
          }}
        >
          <ReactLoading type={"balls"} color="#4c83d9" />
          <Typography variant="p">Loading...</Typography>
        </Box>
      );
}

export default Loading