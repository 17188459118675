import { Container, CssBaseline, Typography } from "@mui/material";
import React from "react";

const BillUploadSuccess = () => {
  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ my: 4 }}>
        <Typography variant="h3" color="green">
          Bill Uploaded Successfully
        </Typography>
        <Typography variant="h6">Thanks for submitting bill, we will review and activate your license soon.</Typography>
      </Container>
    </>
  );
};

export default BillUploadSuccess;
