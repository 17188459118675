import { Box, Typography } from "@mui/material";
import React from "react";

function PaymentSuccess() {
  // useEffect(() => {
  //   const currentUrl = window.location.href;

  //   const url = new URL(currentUrl);
  //   const id = url.searchParams.get("id");
  //   // console.log({ id });
  //   return () => {};
  // }, []);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignSelf: "center",
          m: 1,
          p: 1,
          bgcolor: (theme) => (theme.palette.mode === "dark" ? "#101010" : "#fff"),
          color: (theme) => (theme.palette.mode === "dark" ? "grey.300" : "grey.800"),
          border: "1px solid",
          borderColor: (theme) => (theme.palette.mode === "dark" ? "grey.800" : "grey.300"),
          borderRadius: 2,
        }}
      >
        <Box sx={{textAlign:'center'}}>
          {" "}
          <Typography variant="h5">Payment Successful</Typography>
          <img src="/Images/animGreenTic.gif" alt="green_tic" />
          <Typography variant="body2">Thanks for choosing us.</Typography>
        </Box>
      </Box>
    </div>
  );
}

export default PaymentSuccess;
