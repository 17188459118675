import React, { lazy, Suspense, useState } from "react";
import { Navigate } from "react-router-dom";
import { MainLayout } from "./components";
// import Dashboard from "./pages/Dashboard/Dashboard";
// import LicensesList from "./pages/Licenses/LicensesList";
// import ClientList from "./pages/Clients/ClientList";
// import ProductList from "./pages/Products/ProductList";

// import ViewClient from "./pages/Clients/ViewClient";
// import Requests from "./pages/Requests/Requests";
// import Operator from "./pages/Operator/Operator";
// import HowToUse from "./pages/HowToUse";
// import HowToUseSDK from "./pages/HowToUseSDK";
// import ProductDetails from "./pages/Products/ProductDetails";
// import Settings from "./pages/Setting/Settings";
// import TeamMembers from "./pages/Teams/TeamMembers";
// import UpgradeLicense from "./pages/MyLicense/UpgradeLicense";
// import MyLicense from "./pages/MyLicense/MyLicense";

import { Route, Routes } from "react-router";
import { Box, Typography } from "@mui/material";
import Loading from "./components/Loading";

export default function AuthRoutes() {
  const ViewClient = lazy(() => import("./pages/Clients/ViewClient"));
  const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
  const LicensesList = lazy(() => import("./pages/Licenses/LicensesList"));
  const ClientList = lazy(() => import("./pages/Clients/ClientList"));
  const ProductList = lazy(() => import("./pages/Products/ProductList"));
  const Requests = lazy(() => import("./pages/Requests/Requests"));
  const Operator = lazy(() => import("./pages/Operator/Operator"));
  const HowToUse = lazy(() => import("./pages/HowToUse"));
  const HowToUseSDK = lazy(() => import("./pages/HowToUseSDK"));
  const ProductDetails = lazy(() => import("./pages/Products/ProductDetails"));
  const Settings = lazy(() => import("./pages/Setting/Settings"));
  const TeamMembers = lazy(() => import("./pages/Teams/TeamMembers"));
  const UpgradeLicense = lazy(() => import("./pages/MyLicense/UpgradeLicense"));
  const MyLicense = lazy(() => import("./pages/MyLicense/MyLicense"));
  const AuditTrail = lazy(() => import("./pages/AuditTrail/AuditTrail"));
  const VideosPage = lazy(() => import("./pages/HowToVideos/VideosPage"));

  const [isAuthenticated, setIsAuthenticated] = useState(sessionStorage.getItem("isLogin") == "true" ? true : false);

  if (!isAuthenticated) {
    // return <Navigate to="/" />;
  }

  return (
    <Routes>
      <Route
        path="/dashboard"
        element={
          <MainLayout>
            <Suspense fallback={<Loading />}>
              <Dashboard />
            </Suspense>
          </MainLayout>
        }
      />
      <Route
        path="/clients"
        element={
          <MainLayout>
            <Suspense fallback={<Loading />}>
              <ClientList />
            </Suspense>
          </MainLayout>
        }
      />
      <Route
        path="/clients/view/:clientId"
        element={
          <MainLayout>
            <Suspense fallback={<Loading />}>
              <ViewClient />
            </Suspense>
          </MainLayout>
        }
      />
      <Route
        path="/products"
        element={
          <MainLayout>
            <Suspense fallback={<Loading />}>
              <ProductList />
            </Suspense>
          </MainLayout>
        }
      />

      <Route
        path="/products/:productId"
        element={
          <MainLayout>
            <Suspense fallback={<Loading />}>
              <ProductDetails />
            </Suspense>
          </MainLayout>
        }
      />
      <Route
        path="/licenses"
        element={
          <MainLayout>
            <Suspense fallback={<Loading />}>
              <LicensesList />
            </Suspense>
          </MainLayout>
        }
      />
      <Route
        path="/requests"
        element={
          <MainLayout>
            <Suspense fallback={<Loading />}>
              <Requests />
            </Suspense>
          </MainLayout>
        }
      />

      <Route
        path="/settings"
        element={
          <MainLayout>
            <Suspense fallback={<Loading />}>
              <Settings />
            </Suspense>
          </MainLayout>
        }
      />

      <Route
        path="/how-to-videos"
        element={
          <MainLayout>
            <Suspense fallback={<Loading />}>
              <VideosPage />
            </Suspense>
          </MainLayout>
        }
      />
      <Route
        path="/how-to-use"
        element={
          <MainLayout>
            <Suspense fallback={<Loading />}>
              <HowToUse />
            </Suspense>
          </MainLayout>
        }
      />
      <Route
        path="/how-to-use-sdk"
        element={
          <MainLayout>
            <Suspense fallback={<Loading />}>
              <HowToUseSDK />
            </Suspense>
          </MainLayout>
        }
      />

      <Route
        path="/teams"
        element={
          <MainLayout>
            <Suspense fallback={<Loading />}>
              <TeamMembers />
            </Suspense>
          </MainLayout>
        }
      />
      <Route
        path="/operator"
        element={
          <MainLayout>
            <Suspense fallback={<Loading />}>
              <Operator />
            </Suspense>
          </MainLayout>
        }
      />
      <Route
        path="/audits"
        element={
          <MainLayout>
            <Suspense fallback={<Loading />}>
              <AuditTrail />
            </Suspense>
          </MainLayout>
        }
      />
      <Route
        path="/my/license"
        element={
          <MainLayout>
            <Suspense fallback={<Loading />}>
              <MyLicense />
            </Suspense>
          </MainLayout>
        }
      />
      <Route
        path="/my/license/upgrade"
        element={
          <MainLayout>
            <Suspense fallback={<Loading />}>
              <UpgradeLicense />
            </Suspense>
          </MainLayout>
        }
      />
    </Routes>
  );
}
