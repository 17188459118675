import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { MainListItems, secondaryListItems } from "../SideBarMenu";
import styled from "@emotion/styled";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Button, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Select } from "@mui/material";
import PermIdentitySharpIcon from "@mui/icons-material/PermIdentitySharp";
import CustomToast from "../CustomToast";
import { apiService } from "../../services/ApiService";
import { toast } from "react-toastify";
import {
  AppRegistrationOutlined,
  DeleteOutline,
  DescriptionOutlined,
  DetailsOutlined,
  DocumentScannerOutlined,
  DownloadOutlined,
  DynamicFeedOutlined,
  EditNote,
  Launch,
  SyncAltRounded,
} from "@mui/icons-material";

import copy from "copy-to-clipboard";
import { UserContext } from "../../context/UserProvider";
import { PlaygroundContext } from "../../context/PlaygroundProvider";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
      style={{
        position: "fixed",
        bottom: 0,
        marginLeft: "25%",
        marginRight: "auto",
        transform: "translate(50%, 0)",
      }}
    >
      {"Copyright © "}
      <Link color="inherit" href="http://www.blue-bricks.com/">
        {window?.location?.hostname || "Blue Bricks"}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    backgroundColor: "#3a3838",
    color: "white",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const PlaygroundLayout = ({ children }) => {
  const { currentSdk, setCurrentSdk, currentFeature, setCurrentFeature } = React.useContext(PlaygroundContext);
  const navigate = useNavigate();
  const _color = "#1976d2";
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <main className="">
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open} sx={{ backgroundColor: "#3a3838" }}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "1rem",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-start" }}
            >
              {"{SDK} Playground"}
            </Typography>

            <Typography variant="h6" sx={{ mx: 1 }}>
              SDK:
            </Typography>

            <Select
              value={currentSdk}
              onChange={(e) => {
                setCurrentSdk(e?.target.value);
              }}
              variant="outlined"
              size="small"
              sx={{ minWidth: "150px", color: "white" }}
            >
              <MenuItem key={1} value={"JavaScript/TypeScript"}>
                JavaScript/TypeScript
              </MenuItem>
              <MenuItem key={2} value={"Python"}>
                Python
              </MenuItem>
              <MenuItem key={3} value={"Java"}>
                Java
              </MenuItem>
            </Select>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open} sx={{ backgroundColor: "#3a3838" }}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              backgroundColor: "white",
              px: [1],
            }}
          >
            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
              {" "}
              <img src="/Valydate4u.png" style={{ margin: "auto 0.5rem", width: "100%", height: "100%" }} />
              {/* Licensing */}
            </Typography>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <ListItemButton
              onClick={() => {
                setCurrentFeature('Description')
                navigate("/sdk/playground");
              }}
              sx={{ color: window.location.pathname.includes("playground") ? _color : "" }}
              selected={window.location.pathname.includes("playground")}
            >
              <ListItemIcon sx={{ color: window.location.pathname.includes("teams") ? _color : "" }}>
                <DescriptionOutlined fontSize="small" sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: 15,
                  fontWeight: "medium",
                  lineHeight: "20px",
                }}
                primary="Description"
              />
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                setCurrentFeature('Initialize License')
                navigate("/sdk/playground/init");
              }}
              sx={{ color: window.location.pathname.includes("init") ? _color : "" }}
              selected={window.location.pathname.includes("init")}
            >
              <ListItemIcon sx={{ color: window.location.pathname.includes("init") ? _color : "" }}>
                <EditNote fontSize="small" sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: 15,
                  fontWeight: "medium",
                  lineHeight: "20px",
                }}
                primary="Initialize Client"
              />
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                setCurrentFeature('Update License')
                navigate("/sdk/playground/update");
              }}
              sx={{ color: window.location.pathname.includes("update") ? _color : "" }}
              selected={window.location.pathname.includes("update")}
            >
              <ListItemIcon sx={{ color: window.location.pathname.includes("update") ? _color : "" }}>
                <AppRegistrationOutlined fontSize="small" sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: 15,
                  fontWeight: "medium",
                  lineHeight: "20px",
                }}
                primary="Update License"
              />
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                setCurrentFeature('Get Details')
                navigate("/sdk/playground/details");
              }}
              sx={{ color: window.location.pathname.includes("details") ? _color : "" }}
              selected={window.location.pathname.includes("details")}
            >
              <ListItemIcon sx={{ color: window.location.pathname.includes("details") ? _color : "" }}>
                <DocumentScannerOutlined fontSize="small" sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: 15,
                  fontWeight: "medium",
                  lineHeight: "20px",
                }}
                primary="Get License Details"
              />
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                setCurrentFeature('Feature')
                navigate("/sdk/playground/features");
              }}
              sx={{ color: window.location.pathname.includes("features") ? _color : "" }}
              selected={window.location.pathname.includes("features")}
            >
              <ListItemIcon sx={{ color: window.location.pathname.includes("features") ? _color : "" }}>
                <DynamicFeedOutlined fontSize="small" sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: 15,
                  fontWeight: "medium",
                  lineHeight: "20px",
                }}
                primary="Get Features"
              />
            </ListItemButton>

            <ListItemButton
              disabled
              onClick={() => {
                setCurrentFeature('Sync')
                navigate("/sdk/playground/sync");
              }}
              sx={{ color: window.location.pathname.includes("sync") ? _color : "" }}
              selected={window.location.pathname.includes("sync")}
            >
              <ListItemIcon sx={{ color: window.location.pathname.includes("sync") ? _color : "" }}>
                <SyncAltRounded fontSize="small" sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: 15,
                  fontWeight: "medium",
                  lineHeight: "20px",
                }}
                primary="Sync License"
              />
            </ListItemButton>

            <ListItemButton
              disabled
              onClick={() => {
                setCurrentFeature('Delete')
                navigate("/sdk/playground/delete");
              }}
              sx={{ color: window.location.pathname.includes("delete") ? _color : "" }}
              selected={window.location.pathname.includes("delete")}
            >
              <ListItemIcon sx={{ color: window.location.pathname.includes("delete") ? _color : "" }}>
                <DeleteOutline fontSize="small" sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: 15,
                  fontWeight: "medium",
                  lineHeight: "20px",
                }}
                primary="Delete License"
              />
            </ListItemButton>

            
            <ListItemButton
              onClick={() => {
                setCurrentFeature('Encr-Decr')
                navigate("/sdk/playground/encr-decr");
              }}
              sx={{ color: window.location.pathname.includes("encr-decr") ? _color : "" }}
              selected={window.location.pathname.includes("encr-decr")}
            >
              <ListItemIcon sx={{ color: window.location.pathname.includes("encr-decr") ? _color : "" }}>
                <DeleteOutline fontSize="small" sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: 15,
                  fontWeight: "medium",
                  lineHeight: "20px",
                }}
                primary="Encrypt & Decrypt"
              />
            </ListItemButton>

          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ my: 4 }}>
            <CustomToast />
            {children}

            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </main>
  );
};

export default PlaygroundLayout;
