import React from 'react'
import { ToastContainer } from 'react-toastify'

const CustomToast = () => {
  return (
    <ToastContainer
        position="top-center"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
  )
}

export default CustomToast