import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { apiService } from "../services/ApiService";

export const AdminContext = createContext({});

const AdminProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [myRole, setMyRole] = useState(null);


  return (
    <AdminContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        myRole, setMyRole,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminProvider;
