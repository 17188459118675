import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { apiService } from "../../services/ApiService";
import { toast } from "react-toastify";
import { LoginPage } from "../../components/LoginRegistration/LoginPage";
import { RegistrationPage } from "../../components/LoginRegistration/RegistrationPage";

export const LoginRegistration = () => {
  const [loginRegPage, setLoginRegPage] = useState(false);

  useEffect(() => {
    const islogin = sessionStorage.getItem("isLogin") ? true : false;
    const operatorId = sessionStorage.getItem("operatorId");
    if (islogin && operatorId) {
      window.location.href = "/dashboard";
    } else {
      sessionStorage.removeItem("isLogin");
    }
  }, []);

 
  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      {/* <CssBaseline /> */}
      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          backgroundImage: `url(${require("../../assets/LoginPageBG.png")})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={9} md={6} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 3,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!loginRegPage ? (
            <LoginPage setLoginRegPage={setLoginRegPage} />
          ) : (
            <RegistrationPage setLoginRegPage={setLoginRegPage} />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
