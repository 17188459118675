import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import CustomToast from "../CustomToast";
import { Translate } from "@mui/icons-material";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
      sx={{
        position: 'fixed',
        bottom: 0,
        marginLeft: "50%",
        marginRight: 'auto',
        transform: 'translate(-50%, 0)',
      }}
    >
      {"Copyright © "}
      <Link color="inherit" href="http://www.blue-bricks.com/">
        {window?.location?.hostname || "Blue Bricks"}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const BlankLayout = ({ children }) => {
  return (
    <main className="">
      <CustomToast />
      {children}
      <Copyright sx={{ my: 1 }} />
    </main>
  );
};

export default BlankLayout;
