import React, { lazy, Suspense, useState } from "react";
import { Route, Routes } from "react-router";

import Loading from "./components/Loading";
import AdminLayout from "./components/Layouts/AdminLayout";

export default function AdminAuthRoutes() {
  const Users = lazy(() => import("./pages/ADMIN/Users"));
  const Settings = lazy(() => import("./pages/ADMIN/Settings"));
  const Organizations = lazy(() => import("./pages/ADMIN/Organizations"));
  const ViewOrg = lazy(() => import("./components/ADMIN/ViewOrg"));
  const Requests = lazy(() => import("./pages/ADMIN/Requests"));

  const [isAuthenticated, setIsAuthenticated] = useState(sessionStorage.getItem("isLogin") == "true" ? true : false);

  if (!isAuthenticated) {
    // return <Navigate to="/" />;
  }
  return (
    <Routes>
      <Route
        path="/admin/organizations"
        element={
          <AdminLayout>
            <Suspense fallback={<Loading />}>
              <Organizations />
            </Suspense>
          </AdminLayout>
        }
      />

      <Route
        path="/admin/organizations/:id"
        element={
          <AdminLayout>
            <Suspense fallback={<Loading />}>
              <ViewOrg />
            </Suspense>
          </AdminLayout>
        }
      />

      <Route
        path="/admin/users"
        element={
          <AdminLayout>
            <Suspense fallback={<Loading />}>
              <Users />
            </Suspense>
          </AdminLayout>
        }
      />

      <Route
        path="/admin/requests"
        element={
          <AdminLayout>
            <Suspense fallback={<Loading />}>
              <Requests />
            </Suspense>
          </AdminLayout>
        }
      />

      <Route
        path="/admin/settings"
        element={
          <AdminLayout>
            <Suspense fallback={<Loading />}>
              <Settings />
            </Suspense>
          </AdminLayout>
        }
      />
    </Routes>
  );
}
