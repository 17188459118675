import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import styled from "@emotion/styled";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import PermIdentitySharpIcon from "@mui/icons-material/PermIdentitySharp";
import CustomToast from "../CustomToast";
import { apiService } from "../../services/ApiService";
import { toast } from "react-toastify";
import { AdminPanelSettings, Business, FormatListBulleted, People, Settings } from "@mui/icons-material";

import { UserContext } from "../../context/UserProvider";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
      style={{
        position: "fixed",
        bottom: 0,
        marginLeft: "25%",
        marginRight: "auto",
        transform: "translate(50%, 0)",
      }}
    >
      {"Copyright © "}
      <Link color="inherit" href="http://www.blue-bricks.com/">
        {window?.location?.hostname || "Blue Bricks"}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const AdminLayout = ({ children }) => {
  const { myOrgId, setMyOrgId, userOrgList, getAccessRefreshToken } = React.useContext(UserContext);

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutUser = (event) => {
    sessionStorage.clear();
    window.location.replace("/admin");
  };

  setInterval(() => {
    try {
      apiService
        .post("admin/token/refreshToken", {})
        .then((res) => res?.data || res)
        .then((response) => {
          toast.dismiss();
          // console.log(response);
          if (response.resultCode > 0) {
            sessionStorage.setItem("isLogin", true);
          } else {
            toast.error(response.message || "Login expired");
            setTimeout(() => {
              sessionStorage.removeItem("isLogin");
              sessionStorage.removeItem("jwtToken");
              window.location.href = "/";
            }, 3000);
          }
        })
        .catch((err) => {
          toast.dismiss();
          toast.error("Login expired.");
          sessionStorage.removeItem("isLogin");
          sessionStorage.removeItem("jwtToken");
          window.location.href = "/";
          console.log(err);
        });
    } catch (error) {
      console.log(error);
      toast.error("Login expired..");
      sessionStorage.removeItem("isLogin");
      sessionStorage.removeItem("jwtToken");
      window.location.href = "/";
    }
  }, 540000);

  const viewAdminPage = (path) => {
    let existingPath = window.location.href;

    if (existingPath.includes("localhost") || existingPath.includes("/admin")) {
      navigate(`/admin/${path}`);
    } else {
      navigate(path);
    }
  };

  return (
    <main className="">
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MuiAppBar
          position="absolute"
          open={open}
          sx={{ backgroundColor: "#e4e8ec", marginLeft: drawerWidth, width: `calc(100% - ${drawerWidth}px)` }}
        >
          <Toolbar
            sx={{
              justifyContent: "end",
            }}
          >
            <IconButton color="inherit" onClick={handleMenu}>
              <Avatar>
                <PermIdentitySharpIcon />
              </Avatar>
            </IconButton>
            <Menu
              id="menu-appbar"
              sx={{ mt: "45px" }}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={logoutUser}>Logout</MenuItem>
            </Menu>
          </Toolbar>
        </MuiAppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              flexDirection: "column",
              px: [1],
            }}
          >
            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
              {" "}
              <img src="/Valydate4u.png" style={{ margin: "0.4rem 0.5rem", width: "100%", height: "100%" }} />
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                m: 3,
                backgroundColor: "#e4e8ec",
                width: "95%",
                borderRadius: "15px",
                py: 2,
              }}
            >
              <AdminPanelSettings fontSize="large" />

              <Typography sx={{ alignSelf: "center", fontSize: 15, fontWeight: "medium" }}>Super Admin</Typography>
            </Box>
          </Toolbar>

          <List component="nav">
            <ListItemButton
              onClick={() => {
                viewAdminPage("organizations");
              }}
            >
              <ListItemIcon>
                <Business fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: 15,
                  fontWeight: "medium",
                  lineHeight: "20px",
                }}
                primary="Organizations"
              />
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                viewAdminPage("users");
              }}
            >
              <ListItemIcon>
                <People fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: 15,
                  fontWeight: "medium",
                  lineHeight: "20px",
                }}
                primary="Users"
              />
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                viewAdminPage("requests");
              }}
            >
              <ListItemIcon>
                <FormatListBulleted fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: 15,
                  fontWeight: "medium",
                  lineHeight: "20px",
                }}
                primary="Requests"
              />
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                viewAdminPage("settings");
              }}
            >
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: 15,
                  fontWeight: "medium",
                  lineHeight: "20px",
                }}
                primary="Settings"
              />
            </ListItemButton>
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ my: 4 }}>
            <CustomToast />
            {children}

            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </main>
  );
};

export default AdminLayout;
