import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { apiService } from "../services/ApiService";

export const PlaygroundContext = createContext({});

const PlaygroundProvider = ({ children }) => {
  const [currentSdk, setCurrentSdk] = useState("JavaScript/TypeScript");
  const [currentFeature, setCurrentFeature] = useState("");

  return (
    <PlaygroundContext.Provider
      value={{
        currentSdk,
        setCurrentSdk,
        currentFeature,
        setCurrentFeature,
      }}
    >
      {children}
    </PlaygroundContext.Provider>
  );
};

export default PlaygroundProvider;
