import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.credentials = "include";

let Hosted_Base_URL = window?.location?.origin;

const API_BASE_URL = process.env?.REACT_APP_API_BASE_URL || `${Hosted_Base_URL}/api`;


const apiService = axios.create({ baseURL: `${API_BASE_URL}/` });

// Add a request interceptor
apiService.interceptors.request.use(
  function (config) {
    // Append opkey as a query parameter to each request
    const opkey = sessionStorage.getItem("opkey");
    if (opkey) {
      config.params = { ...config.params, opkey };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export { apiService };
