import {
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
  FormControl,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { apiService } from "../../services/ApiService";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";

const PasswordSet = () => {
  const { userId } = useParams();
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");

  useEffect(() => {
    const currentUrl = window.location.href;

    const url = new URL(currentUrl);
    const opkey = url.searchParams.get("opkey");

    sessionStorage.setItem("opkey", opkey);
  }, []);

  const handleSubmit = (event) => {
    try {
      event.preventDefault();

      if (!password || !cPassword) {
        toast.warn("All fields are required!");
        return;
      }
      if (password.trim() != cPassword.trim()) {
        toast.warn("New Password and Re-entered password does not match!");
        return;
      }

      const formData = {
        userId: userId,
        password: password.trim(),
      };

      console.log({ formData });

      if (!formData.userId || !formData.password) {
        return;
      }

      apiService
        .post("authenticate/operator/setPassword", formData)
        .then((res) => res?.data || res)
        .then((response) => {
          toast.dismiss();
          if (response.resultCode > 0) {
            toast.success(response.message);
            setTimeout(() => {
              window.location.href = "/";
            }, 2000);
          } else {
            toast.error(response.message || "Fail");
          }
        })
        .catch((err) => {
          toast.dismiss();
          console.log(err);
          if (err?.response && err?.response?.data) {
            toast.error(err?.response?.data?.message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <form onSubmit={handleSubmit}>
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img src="/Valydate4u.png" style={{ margin: "auto 0.5rem", width: "16rem", height: "100%" }} />
            </Box>
            <Typography component="h5" variant="h5" align="center">
              Set New Password and Activate
            </Typography>

            <Divider sx={{ my: 2 }} />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="password"
                  type="password"
                  name="password"
                  label="Password"
                  fullWidth
                  helperText="abc@123"
                  variant="standard"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  id="cPassword"
                  type="password"
                  name="cPassword"
                  label="Re-enter Password"
                  helperText="abc@123"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                    setCPassword(e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" type="submit" sx={{ mt: 3, ml: 1 }}>
                Submit
              </Button>
            </Box>
          </Paper>
        </form>
      </Container>
    </>
  );
};

export default PasswordSet;
